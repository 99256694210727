<script>
export default {
  name: "Maintenance"
}
</script>

<template>
  <div class="maintenance-page">
    <div class="maintenance-page__background-image"></div>
    <section class="section">
      <div class="container-xxl">
        <div class="maintenance-page__content-grid">
          <div class="maintenance-page__title-top">Coming Soon</div>
          <div class="maintenance-page__logo-center">
            <div class="maintenance-page__logo-box">
              <img :src="require(`@/assets/images/cdreamslogo.png`)" alt="">
            </div>
          </div>
          <div class="maintenance-page__text-bottom">En construction<br>Under construction</div>
        </div>
      </div>
    </section>
  </div>
</template>
